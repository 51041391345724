/* Basic configuration to body */
html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

/* behavior of the scroll */
html {
  scroll-behavior: smooth;
}

/* Scrollbar modifications */
::-webkit-scrollbar{
  width: 7px;
  background-color: rgb(219, 219, 219);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #afafaf;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #818181;
}

::-webkit-scrollbar-thumb:active { 
  background-color: #5c5c5c;
}  

::-webkit-scrollbar-track{
  width: 7px;
}

/* To justify the text */
.text-justify {
  text-align: justify;
}

/* To change ul colors */
ul li {
  color: #ea7a69;
}

ul li p {
  color: black;
}
