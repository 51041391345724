/*===== Principal app div =====*/
.App {
  text-align: center;
  background-color: #f8f9fa;
  z-index: 0;
}

/* scroll to top button */
.scroll-top {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  padding: 0;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 2;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* Whatsapp buton */
.wsp-but {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  padding: 0;
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 2;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.wsp-but:hover {
  background-color: #1bc75a;
  color: #fff;
}

/*===== Header CSS =====*/
.header a {
  background: transparent;
  color: #000000;
  text-decoration: none;
}

/*===== NavBar CSS =====*/
.navbar-nav a.nav-link {
  color: #9e9e9e;
}

.navbar-nav a.nav-link.active {
  color: #020202;
  background-color: transparent;
  font-weight: bold;
}

.collapse a.call:hover {
  color: #ea7a69;
}

/* When the navbar uses the same background */
.nav-same-back {
  background-color: #f8f9fa;
}

/* When the navbar uses a different background */
.nav-back-diff {
  background-color: #fff;
}

/*===== Hero CSS =====*/
.hero-section {
  overflow: hidden;
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 50px;
}

.hero-content {
  margin-bottom: 60px;
  max-width: 500px;
}

/*===== Feactures CSS =====*/
.feactures-section {
  padding: 0;
  margin-top: 120px;
  margin-bottom: 50px;
}

.feactures-content {
  margin-bottom: 50px;
}

.feacture-text {
  max-width: 580px;
}

/*===== Contact CSS =====*/
.contact-section {
  margin-top: 120px;
  margin-bottom: 80px;
}

/*===== Footer CSS =====*/
.footer {
  margin-top: 30px;
  padding-left: 35px;
  padding-right: 35px;
}

.footer .footer-slot {
  margin-bottom: 50px;
}

.footer .footer-slot .social-links {
  display: flex;
  align-items: center;
  justify-content: space-evenly; 
  margin-top: 35px;
}

.footer .footer-slot h3 {
  margin-bottom: 20px;
}

/* footer follow me buttons */
.face {
  color: #395498;
  font-size: 32px;
  display: flex;
}

.insta {
  color: white;
  width: 32px;
  height: 32px;
  display: flex;
  font-size: 28px;
  border-radius: 29%;
  align-items: center;
  justify-content: center;
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.linke {
  color: #0270ad;
  font-size: 32px;
  display: flex;
}

.face:hover {
  color: #395498;
  transform: scale(1.2);
}

.insta:hover {
  color: white;
  transform: scale(1.2);
}

.linke:hover {
  color: #0270ad;
  transform: scale(1.2);
}

/* Invert the column when it is less than 576px */
@media (max-width: 576px) {
  .breakpoint-sm {
    flex-flow: row wrap-reverse
  }
}

/* When darkmode is enable */
@media (prefers-color-scheme: dark) {
  .App {
    background-color: var(--bs-dark);
    color: var(--bs-light);
  }

  .navbar.nav-same-back {
    background-color: var(--bs-dark);
    color: var(--bs-light);
  }

  .navbar.nav-same-back h1 {
    color: var(--bs-light);
  }

  .navbar.nav-same-back h2 {
    color: var(--bs-light);
  }

  .navbar.nav-same-back h4 {
    color: var(--bs-light);
  }

  .navbar.nav-same-back h6 {
    color: var(--bs-light);
  }

  .navbar-nav a.nav-link.active {
    color: var(--bs-light);
  }

  .navbar.nav-back-diff {
    background-color: #111111;
    color: var(--bs-light);
  }

  .navbar.nav-back-diff h1 {
    color: var(--bs-light);
  }

  .navbar.nav-back-diff h2 {
    color: var(--bs-light);
  }

  .navbar.nav-back-diff h4 {
    color: var(--bs-light);
  }

  .navbar.nav-back-diff h6 {
    color: var(--bs-light);
  }

  .navbar-toggler {
    background-color: var(--bs-light);
    color: #929292;
  }

  .card {
    background-color: var(--bs-dark);
    color: var(--bs-light);
  }

  .lead {
    color: var(--bs-light);
  }
}
